export default {
  data: () => ({
    na: false,
  }),

  watch: {
    na () {
      if (this.na) {
        this.onUpdateValue('NA')
      } else {
        this.onUpdateValue(0)
      }
    }
  },

  mounted () {
    if (this.value === 'NA') {
      this.na = true
    }
  },
}
