export default {
  mounted () {
    this.localeValue = this.value
  },

  watch: {
    value: function () {
      this.localeValue = this.value
    }
  },

  data: () => ({
    localeValue: null,
  }),
}
