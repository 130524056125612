export default {
  data: () => ({
    nc: false,
  }),

  watch: {
    nc () {
      if (this.nc) {
        this.onUpdateValue('NC')
      } else {
        this.onUpdateValue(0)
      }
    }
  },

  mounted () {
    if (this.value === 'NC') {
      this.nc = true
    }
  },
}
